export enum ERRORS {
  INTERNAL_ERROR = 'Ops, ocorreu um erro inesperado',
  LOADING_ERROR = 'Não foi possível carregar as informações da sua conta',
  DOES_NOT_EXIST = 'Esta página não existe',
  BOLETO_DOES_NOT_EXIST = 'Este boleto não existe',
  MISSING_INVOICING_PLAN_ID = 'O ID do plano de faturamento é obrigatório',
  ADD_PUSH_TOKEN_FAIL = 'Não foi possível adicionar o token de push',
  DELETE_PUSH_TOKEN_FAIL = 'Não foi possível deletar o token de push',
  UPDATE_PERSON_FAIL = 'Não foi possível salvar a alteração.',
  UPDATE_PLACE_FAIL = 'Não foi possível atualizar informações do lugar',
  TRIGGER_FIRST_ACCESS_FAIL = 'Não foi possível iniciar o primeiro acesso',
}

export const INTERNAL_ERROR = 'Ops, ocorreu um erro inesperado'

export const AUTH_ERRORS = {
  NO_METHODS: 'Nenhum método de contato encontrado para esse documento',
  INVALID_PERSONAL_DOCUMENT: 'Por favor, insira um CPF válido',
  CUSTOMER_NOT_FOUND: 'Parece que este CPF não está cadastrado',
  INVALID_CODE: 'Código de acesso inválido',
  EXPIRED: 'Código expirado, por favor reenvie o código',
  REQUIRED_CODE: 'Por favor, insira o código que enviamos',
  REQUIRED: 'Por favor, insira seu CPF para continuar',
}

export enum STATUS_TEXTS {
  INVALID_ROUTE = 'Invalid Route',
  UNCAUGHT_EXCEPTION = 'Uncaught Exception',
}

export const INVOICE_REQUIRED = 'Invoice is required'
export const INVOICE_NOTIFICATIONS_REQUIRED =
  'Invoice notifications are required'

export const MIDDLEWARE_ERROR = (name: string, message: string) => {
  const error_message = `Middleware error [${name}]: ${message}`
  return new Error(error_message)
}
